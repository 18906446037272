import React, { useEffect, useRef } from 'react';
import './Layout.scss';
import { useCookies } from 'react-cookie';

export interface IWheelProps {
    onComplete: (result: Result) => void;
}

export enum Result {
    Trick = 'trick',
    Treat = 'treat'
}

export const Wheel: React.FC<IWheelProps> = ({onComplete}) => {

    const wheelRef = useRef<HTMLCanvasElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [cookies, setCookie] = useCookies();

    useEffect(() => {
        if (cookies.result) {
            onComplete(cookies.result);
        }

    }, []);



    const sectors = [
        {color:"#7ed049", label:"Trick"},
        {color:"#f59d1e", label:"Treat"},
        {color:"#7ed049", label:"Trick"},
        {color:"#f59d1e", label:"Treat"},
        {color:"#7ed049", label:"Trick"},
        {color:"#f59d1e", label:"Treat"},
        {color:"#7ed049", label:"Trick"},
        {color:"#f59d1e", label:"Treat"},
    ];
    
    const rand = (m, M) => Math.random() * (M - m) + m;
    const tot = sectors.length;

    let hasSpun = false;
    
    let ctx
    let dia
    let rad
    let PI
    let TAU
    let arc
    
    const friction = 0.991;
    let angVel = 0;
    let ang = 0;
    
    const getIndex = () => Math.floor(tot - ang / TAU * tot) % tot;
    
    function drawSector(sector, i) {
        const ang = arc * i;
        ctx.save();

        ctx.beginPath();
        ctx.fillStyle = sector.color;
        ctx.moveTo(rad, rad);
        ctx.arc(rad, rad, rad, ang, ang + arc);
        ctx.lineTo(rad, rad);
        ctx.fill();

        ctx.translate(rad, rad);
        ctx.rotate(ang + arc / 2);
        ctx.textAlign = "right";
        ctx.fillStyle = "#fff";
        ctx.font = "bold 20px Nosifer";
        ctx.fillText(sector.label, rad - 10, 10);
        
        ctx.restore();
    };
    
    function rotate() {
        const sector = sectors[getIndex()];
        ctx.canvas.style.transform = `rotate(${ang - PI / 2}rad)`;
        buttonRef.current.textContent = !hasSpun ? "SPIN" : sector.label;
        buttonRef.current.style.background = sector.color;

        let numWeeks = 1;
        let now = new Date();
        now.setDate(now.getDate() + numWeeks * 7);

        if (hasSpun && !angVel) {
            if (sector.label === 'Trick') {
                onComplete(Result.Trick);
                setCookie('result', Result.Trick, {
                    expires: now
                })
            } else {
                onComplete(Result.Treat);
                setCookie('result', Result.Treat, {
                    expires: now
                })
            }
        }
    }
    
    function frame() {
        if (!angVel) return;
        angVel *= friction;
        if (angVel < 0.002) angVel = 0;
        ang += angVel;
        ang %= TAU;
        rotate();
    }
      
    function engine() {
        frame();
        requestAnimationFrame(engine)
    }
    
    useEffect(() => {
        ctx = wheelRef.current.getContext('2d');
        dia = ctx.canvas.width;
        rad = dia / 2;
        PI = Math.PI;
        TAU = 2 * PI;
        arc = TAU / sectors.length;
        sectors.forEach(drawSector);
        rotate();
        engine();
    }, []);

    const handleOnClick = () => {
        hasSpun = true;
        if (!angVel) angVel = rand(0.25, 0.35);
    }



    return (
        <div className="WheelContainer">
        <canvas ref={wheelRef} id="wheel" height="350" width="350"></canvas>
        <button ref={buttonRef} id="spin" onClick={() => handleOnClick()}>SPIN</button>
        </div>
    )
}