import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/SEO"
import { Result, Wheel } from "../components/Wheel"
import { Modal } from "../components/Modal";
import HubspotForm from "react-hubspot-form";

const IndexPage = () => {
  const [result, setResult] = React.useState<Result>(null);

  const handleCompleteSpin = (result: Result) => {
    setResult(result);
  }

  return (
  <div className="Page">
    <Seo title="Home" description="" lang="en" />
    <StaticImage
      src="../images/logo.png"
      width={500}
      quality={95}
      formats={["auto", "webp", "avif"]}
      alt="Perspicuity Trick Or Treat"
      style={{ marginBottom: `1.45rem` }}
    />
    <Wheel onComplete={result => handleCompleteSpin(result)} />
    <StaticImage
      src="../images/softcat.png"
      width={300}
      quality={95}
      formats={["auto", "webp", "avif"]}
      alt="Softcat Elite Partner"
      style={{ marginTop: `1.45rem` }}
    />
    <Modal visible={result === Result.Trick}>
      <StaticImage
        src="../images/trick-winner.png"
        width={600}
        quality={95}
        formats={["auto", "webp", "avif"]}
        alt="Perspicuity Trick Or Treat"
        style={{ marginBottom: `1.45rem` }}
      />
            <HubspotForm portalId={'4465129'} formId={'46ac750e-5399-444f-82c8-16de29d765e4'} />

    </Modal>
    <Modal visible={result === Result.Treat}>
      <StaticImage
        src="../images/treat-winner.png"
        width={600}
        quality={95}
        formats={["auto", "webp", "avif"]}
        alt="Perspicuity Trick Or Treat"
        style={{ marginBottom: `1.45rem` }}
      />
      <HubspotForm portalId={'4465129'} formId={'559ad2c6-bd1c-4fbc-85f4-763199cdd471'} />
    </Modal>
  </div>
  );
}

export default IndexPage